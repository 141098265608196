<script>
import Menu from "components/dashboard/menu"
import DashMenu from "components/dashboard/components/DashMenu.vue";

export default {
  name: 'Dashboard',
  inject: {
    erplayout: {
      default() {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  components: {DashMenu},
  data() {
    return {}
  },
  computed: {
    menu () {
      return Menu
    },
    isComitente () {
      return this.hasRole('ROLE_COMITENTE')
    }
  },
  mounted() {
    this.checkUserPermissions()
    this.erplayout.erpheader.menu.setActiveMenu('principal')
    document.querySelector('.u-erp-layout-container').classList.add('dashboard')
  },
  destroyed() {
    document.querySelector('.u-erp-layout-container').classList.remove('dashboard')
  },
  methods: {
    checkUserPermissions() {
    }
  },
  meta: {
    title: 'Dashboard',
    name: 'Dashboard'
  }
}
</script>

<template>
  <layout v-if="!isComitente" no-menu :menu="menu" menu-active="principal" menu-name="ERP" :folded="true" no-title hfull>
    <dash-menu />
    <router-view />
  </layout>
  <div v-else class="flex col-grow no-wrap">
    <div class="app-layout-cmenu comitente-dashboard" style="padding-right: 0">
      <transition name="slide" appear>
        <div class="transition-generic h-full flex items-center justify-center">
          <a class="comit-btn" @click="$router.push({name: 'bens.lista'})">
            <i class="fa-duotone fa-fw fa-car-building"/>
            <span>Bens</span>
          </a>
          <a class="comit-btn" @click="$router.push({name: 'leiloes.lista'})">
            <i class="fa-duotone fa-fw fa-car-building"/>
            <span>Leilões</span>
          </a>
        </div>
      </transition>
    </div>
  </div>
</template>

<style src="./assets/default.styl" lang="stylus"></style>

