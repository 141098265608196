export default [
  {
    path: '/console', component: () => import('./components/include/Page.vue'), name: 'console.page', children: [
      {path: '', component: () => import('./components/Suporte.vue'), name: 'console.suporte'},
      {path: 'tarefas', component: () => import('./components/Tarefas.vue'), name: 'console.tarefas', children: [
          {path: 'board', component: () => import('./components/suporte/tarefas/Kanban.vue'), name: 'console.tarefas.kanban'},
          {path: ':id', component: () => import('./components/suporte/tarefas/Tarefa.vue'), name: 'console.tarefa'}
        ]},
      {path: 'clientes', component: () => import('./components/Clientes.vue'), name: 'console.clientes', children: [
          {path: 'lista', component: () => import('./components/clientes/Lista.vue'), name: 'console.clientes.lista', children: [
              {path: ':id', component: () => import('./components/clientes/Cliente.vue'), name: 'console.clientes.cliente', children: [
                  {path: 'financeiro', component: () => import('./components/clientes/Financeiro.vue'), name: 'console.clientes.cliente.financeiro'}
                ]}
            ]}
        ]},
      {path: 'financeiro', component: () => import('./components/Financeiro.vue'), name: 'console.financeiro'},
      {path: 'leiloes', component: () => import('./components/Leiloes.vue'), name: 'console.leiloes'},
      {path: 'produtos', component: () => import('./components/produtos/Produtos.vue'), name: 'console.produtos', children: [
          {path: ':id', component: () => import('./components/produtos/Produto.vue'), name: 'console.produtos.produto'}
        ]},
    ]
  },
  {
    path: '/console/commander', component: () => import('./Main.vue'), name: 'website.main', children: [
      {
        path: '',
        name: 'console.home',
        component: () => import('./components/Console')
      }
    ]
  }
]
