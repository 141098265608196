import http from '../../../utils/services/http'

export const list = (limit, page, filtros) => {
  let url = '/api/users?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const create = (data, relationalUser = false) => {
  let url = '/api/users'
  if (relationalUser) {
    url = url + '?relationalUser=1'
  }
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/users/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findSimple = (id) => {
  let url = `/api/users/${id}/show-simple`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
export const update = (id, data, includePerson = false, method = 'PUT', updateAcl = false) => {
  let url = `/api/users/${id}`
  const params = []
  if (includePerson) {
    params.push('includePerson=1')
  }
  if (updateAcl) {
    params.push('updateAcl=1')
  }
  if (params.length) {
    url = url + '?' + params.join('&')
  }
  let m
  if (method === 'PATCH') {
    m = http.patch
  } else {
    m = http.put
  }
  return m(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
export const deleteUser = (id) => {
  let url = `/api/users/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
export const updateSimple = (id, data) => {
  let url = `/api/users/${id}/update-simple`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const upgrade = (id, data) => {
  let url = `/api/users/${id}`
  return http.patch(url, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const resetPassword = (id, password = null, query = null) => {
  let url = `/api/users/${id}/resetPassword`
  const extra = []
  if (password) {
    extra.push('pw=' + encodeURI(password).replace(/#/g, '%23'))
  }
  if (query) {
    extra.push(query)
  }

  url = url + '?' + extra.join('&')
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updatePassword = (id, data) => {
  let url = `/api/users/${id}/updatePassword`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateUserProfilePicture = (id, data) => {
  let url = `/api/users/${id}/updateProfilePicture`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const log = (id) => {
  let url = `/api/users/${id}/logs`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const sugerirUsername = (nome) => {
  let url = `/api/users/suggestUsername?nome=${nome}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const permissoes = () => {
  // let url = `/api/users/${id}`
  return new Promise(function (resolve, reject) {
    resolve([
      {
        name: 'uloc',
        description: 'Geral',
        checked: false,
        childs: [
          {
            name: 'user',
            description: 'Administrar usuários',
            checked: false,
            childs: [
              {
                name: 'create',
                description: 'Criar',
                tip: 'Permite criar um usuário',
                checked: false,
              },
              {
                name: 'list',
                description: 'Listar',
                tip: 'Permite listar usuários',
                checked: false,
              },
              {
                name: 'show',
                description: 'Mostrar',
                tip: 'Permite acessar um usuário',
                checked: false,
              },
              {
                name: 'update',
                description: 'Atualizar',
                tip: 'Permite atualizar um usuário',
                checked: false,
              }
            ]
          }
        ]
      }
    ])
  })
}


export const simpleList = (limit, page, filtros, format = null) => {
  let url = '/api/users/simple-list?page=' + page + '&limit=' + limit + filtros
  let config = {}
  if (format && format !== 'html') {
    config.responseType = 'blob'
    config.timeout = 30000
  }
  return http.get(url, config)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const uploadArquivoAvulso = (data) => {
  let url = '/api/user/arquivos-avulsos/upload'
  return http.post(url, data, {
    'timeout': 300000
    // headers: {'Content-Type': 'multipart/form-data'}
  })
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}
