<script>
export default {
  name: "VeiculoVerificado",
  props: {
    bem: {required: true}
  }
}
</script>

<template>
<div v-if="bem.tipoPai === 1">
  <div class="btn-veiculo-verificado">
  </div>
</div>
</template>
