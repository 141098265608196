<script>
import {UTooltip} from "uloc-vue"

export default {
  name: "DashMenu",
  components: {
    UTooltip
  }
}
</script>

<template>
  <div class="erp-dash-menu">
<!--    <ul>
      <li><a>Principal</a></li>
      <li><a>Cadastros</a></li>
      <li><a>Resumo de Bens</a></li>
      <li><a>Suporte</a></li>
    </ul>-->
    <u-btn @click="$router.push({name: 'dashboard.main'})" class="opt-btn no-text" :class="{active: $route.name === 'dashboard.main'}">
      <i class="fa-duotone fa-table-columns" />
      <u-tooltip class="min-tooltip" :offset="[10, 10]">Dashboard principal</u-tooltip>
    </u-btn>
    <u-btn @click="$router.push({name: 'dashboard.cadastros'})" class="opt-btn no-text" :class="{active: $route.name === 'dashboard.cadastros'}">
      <i class="fa-duotone fa-users" />
      <u-tooltip class="min-tooltip" :offset="[10, 10]">Cadastros</u-tooltip>
    </u-btn>
    <u-btn @click="$router.push({name: 'dashboard.bens'})" class="opt-btn no-text" :class="{active: $route.name === 'dashboard.bens'}">
      <i class="fa-duotone fa-garage" />
      <u-tooltip class="min-tooltip" :offset="[10, 10]">Resumo de Bens</u-tooltip>
    </u-btn>
    <u-btn @click="$router.push({name: 'dashboard.bi'})" class="opt-btn no-text" :class="{active: $route.name === 'dashboard.bi'}">
      <i class="fa-duotone fa-chart-pie-simple" />
      <u-tooltip class="min-tooltip" :offset="[10, 10]">Business Intelligence (BI)</u-tooltip>
    </u-btn>
    <u-btn @click="$router.push({name: 'suporte.cliente.dashboard'})" class="opt-btn" :class="{active: $route.name === 'suporte.cliente.dashboard'}"><i class="fa-duotone fa-headset" /> Suporte</u-btn>
  </div>
</template>
