<script>
import {UPopover} from 'uloc-vue'
import {getStatus, STATUS_INTERNO_EM_PREPARACAO, StatusInterno as Status} from '@/domain/leiloes/helpers/LeilaoStatus'
import * as StatusLeilao from '@/domain/leiloes/helpers/LeilaoStatus'
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'

export default {
  name: 'BtnStatusInternoLeilao',
  props: ['leilao', 'loading'],
  components: {MenuOptionsItem, UPopover},
  computed: {
    status () {
      return Object.keys(Status).map((k) => {
        return {
          label: Status[k].title,
          hide: Status[k].hide,
          value: Number(k),
          class: typeof Status[k].title !== 'undefined' ? Status[k].class : null
        }
      })
    },
    StatusLeilao () {
      return StatusLeilao
    },
    STATUS_INTERNO_EM_PREPARACAO () {
      return StatusLeilao.STATUS_INTERNO_EM_PREPARACAO
    },
    statusFiltered () {
      return this.status.filter((s) => !s.hide && s.value !== Number(this.leilao.statusInterno))
    }
  },
  methods: {
    getStatus (s) {
      return getStatus(s, Status)
    },
    updateStatus (s) {
      console.log('Update Status Interno: ', s)
      if (this.leilao.statusInterno === StatusLeilao.STATUS_INTERNO_EM_PREPARACAO) {
        this.prepararLeilao()
      } else {
        this.atualizarStatusInterno()
      }
    },
    prepararLeilao () {
      this.$uloc.dialog({
        title: 'Preparar para Leilão',
        message: 'Ao preparar para leilão, lotes não poderão mais ser removidos ou ter a numeração alterada. Tem certeza ?',
        ok: 'Sim, preparar para leilão',
        cancel: 'Não'
      })
          .then(() => {
            this.$emit('prepararLeilao', StatusLeilao.STATUS_INTERNO_PREPARADO)
          })
          .catch(() => {
          })
    },
    atualizarStatusInterno () {
      this.$uloc.actionSheet({
        title: 'Alterar status interno do leilão',
        grid: false,
        actions: [
          {
            label: 'Voltar para em Preparação',
            icon: 'backward',
            iconType: 'fa',
            color: 'black',
            handler: () => {
              this.$uloc.notify({color: 'grey', message: 'Voltando status para Em Preparação'})
              this.$emit('alteraStatusInterno', StatusLeilao.STATUS_INTERNO_EM_PREPARACAO)
            }
          },
          {
            label: 'Iniciar Recebimento',
            icon: 'cash-register',
            iconType: 'fa',
            color: 'positive',
            handler: () => {
              this.$uloc.notify({color: 'grey', message: 'Alterando status para Em Reebimento'})
              this.$emit('alteraStatusInterno', StatusLeilao.STATUS_INTERNO_EM_RECEBIMENTO)
            }
          },
          {
            label: 'Fechar Leilão',
            icon: 'stop-circle',
            iconType: 'fa',
            color: 'blue',
            handler: () => {
              this.$uloc.notify({color: 'grey', message: 'Fechando leilão...'})
              this.$emit('alteraStatusInterno', StatusLeilao.STATUS_INTERNO_ENCERRADO)
            }
          },
          {}, // separator
          {
            label: 'Cancelar',
            icon: 'times',
            iconType: 'fa',
            color: 'negative',
            handler: () => {
              // this.$uloc.notify('Operação cancelada')
            }
          }
        ]
      })
          .catch(() => {
            // dimiss...
          })
    }
  }
}
</script>

<template>
  <e-btn :disabled="!cracl('ROLE_LEILAO', ['sl/leilao/update', 'sl/leilao/status-leilao'])" :loading="loading" class="w-full text-left leilao-status v2 scolor" align="left" :class="getStatus(leilao.statusInterno).class || ''" :label="getStatus(leilao.statusInterno).title">
    <u-icon name="chevron-down" type="fa" class="m-l-xs m-r-sm text-grey" style="font-size: 10px !important;"/>
    <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
      <div class="menu menu-btn-leilao">
        <ul>
          <menu-options-item v-for="(s, i) in statusFiltered" close :key="i" class="leilao-status scolor" :class="s.class" @click="updateStatus(s.value)">
          <span class="menuLabel">
                <div class="flex col-grow no-wrap justify-between">
                  <div class="flex items-center">
                    <u-icon name="square" type="fa" class="m-r-xs" />
                    <span class="menu-label-content">{{s.label}}</span>
                  </div>
                </div>
            </span>
          </menu-options-item>
        </ul>
        <slot />
      </div>
    </u-popover>
  </e-btn>
</template>
